<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Bidang</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Data Bidang</span>
            </div>
        </div> 

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Form Bidang
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div role="alert" class="alert alert-danger">⚠️ Isi form berikut dengan informasi pejabat yang sedang aktif!!!</div>
                            <div v-if="userdata.role == 'administrator'" class="form-group">
                                <label>SKPD<span class="text-danger">*</span></label>
                                <v-select 
                                        :options="listPengguna"
                                        :reduce="(label) => label.code"
                                        label="label"
                                        :class="form-control"
                                        v-model="form.id_user"
                                        ></v-select>
                            </div>
                            <div class="form-group">
                                <label>Nama Bidang<span class="text-danger">*</span></label>
                                <input required="" type="text" v-model="form.bidang" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Singkatan<span class="text-danger">*</span></label>
                                <input required="" type="text" v-model="form.singkatan" value="" class="form-control" placeholder="">
                                <small>Untuk kebutuhan di penomoran surat</small>
                            </div>
                            <div class="form-group">
                                <label>Nama Kepala Bidang<span class="text-danger">*</span></label>
                                <input required="" type="text" v-model="form.kabid_nama" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>NIP Kepala Bidang</label>
                                <input type="text" v-model="form.kabid_nip" value="" class="form-control" placeholder="">
                            </div>
                            <div class="form-group">
                                <label>Pangkat/Golongan</label>
                                <select class="form-control" v-model="form.kabid_gol">
                                    <option value="">- Pilih Pangkat/Gol -</option>
                                    <option value="Juru muda/Ia">Juru muda/Ia</option>
                                    <option value="Juru Muda Tk.I/Ib">Juru Muda Tk.I/Ib</option>
                                    <option value="Juru/Ic">Juru/Ic</option>
                                    <option value="Juru Tk I/Id">Juru Tk I/Id</option>
                                    <option value="Pengatur Muda/IIa">Pengatur Muda/IIa</option>
                                    <option value="Pengatur Muda Tk.I/IIb">Pengatur Muda Tk.I/IIb</option>
                                    <option value="Pengatur/IIc">Pengatur/IIc</option>
                                    <option value="Pengatur Tk.I/IId">Pengatur Tk.I/IId</option>
                                    <option value="Penata Muda/IIIa">Penata Muda/IIIa</option>
                                    <option value="Penata Muda Tk.I/IIIb">Penata Muda Tk.I/IIIb</option>
                                    <option value="Penata/IIIc">Penata/IIIc</option>
                                    <option value="Penata Tk.I/IIId">Penata Tk.I/IIId</option>
                                    <option value="Pembina/Iva">Pembina/Iva</option>
                                    <option value="Pembina Tk.I/Ivb">Pembina Tk.I/Ivb</option>
                                    <option value="Pembina Utama Muda/Ivc">Pembina Utama Muda/Ivc</option>
                                    <option value="Pembina Utama Madya/Ivd">Pembina Utama Madya/Ivd</option>
                                    <option value="Pembina Utama/Ive">Pembina Utama/Ive</option>
                                </select>
                            </div>
                             <div class="form-group" style="display:none">
                                <label>Nomor Surat Terakhir</label>
                                <input type="number" v-model="form.nomor_surat" value="" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div role="alert" class="alert alert-danger">⚠️ Gunakan fitur <button type="button" class="ml-2 btn btn-secondary btn-sm mr-2"><i class="fe fe-users"></i></button>pada setiap list bidang, untuk memasukan informasi pejabat sebelumnya, agar persuratan mengikuti masa aktif pejabat bidang</div>
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData(1)" placeholder="Cari Nama Bidang...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <!-- HEADER -->
                                    <button class="btn btn-primary ml-2" @click="show_modal()">
                                        <div class="d-flex align-items-center">
                                            <i class="fe fe-plus mr-2"></i>Tambah Bidang
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>Nama Bidang</th>
                                            <th>Kepala Bidang <span class="text-success">(Aktif)</span></th>
                                            <th>Pangkat/Golongan <span class="text-success">(Aktif)</span></th>
                                            <th v-if="userdata.role == 'administrator'">SKPD</th>
                                            <th style="width:10%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in dataBidang" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td v-if="item.singkatan">{{ item.bidang }} | {{ item.singkatan }}</td>
                                            <td v-if="!item.singkatan">{{ item.bidang }}</td>
                                            <td v-if="item.kabid_nip">{{ item.kabid_nip }} | {{ item.kabid_nama }}</td>
                                            <td v-if="!item.kabid_nip">{{ item.kabid_nama }}</td>
                                            <td>{{ item.kabid_gol }}</td>
                                            <td v-if="userdata.role == 'administrator'">{{ item.user }}</td>
                                            <td class="text-center">
                                                <button class="btn btn-secondary btn-sm mr-2">
                                                    <router-link :to="{ name:'master.history',params: { id: item.id }}">
                                                        <i class="text-white fe fe-users"></i>
                                                    </router-link>
                                                </button>
                                                <button @click.prevent="editForm(item.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                <button @click.prevent="postDelete(item.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData(0)"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Bidang'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataBidang: [],
                listPengguna: [],
                fetching: true,
                form: {
                    id: '',
                    id_user: '',
                    bidang: '',
                    singkatan: '',
                    kabid_nama: '',
                    kabid_nip: '',
                    kabid_gol: '',
                    nomor_surat: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData(0);
            this.getUser();
        },
        components: {
            vPagination,
            'v-select' : vSelect,
        },
        computed: mapState(['userdata']),
        methods: {
            show_modal() {
                this.showSelectYear = true;
                this.form.id = '';
                this.form.id_user = '';
                this.form.bidang = '';
                this.form.singkatan = '';
                this.form.kabid_nama = '';
                this.form.kabid_nip = '';
                this.form.kabid_gol = '';
                this.form.nomor_surat = '';
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data data periode
            getData(key) {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/bidang', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        key: key,
                    }
                })
                    .then((response) => {
                        this.dataBidang = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getUser() {
                this.fetching = true;
                if(this.userdata.role == 'administrator'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/select', {
                    params: {
                            page : this.pagination.currentPage,
                            keywords: this.keywords,
                            role: 'dinas',
                        }
                    })
                        .then((response) => {
                            let data = response.data.data;
                            data.forEach((item) => {
                                this.listPengguna.push({
                                label: item.name,
                                code: item.id,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                }
            },
            submitForm() {
                if (this.form.bidang == '' || this.form.singkatan == '' || this.form.kabid_nama == '' ) {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Semua Form Wajib di Isi',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/master/bidang/save', this.form)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Data berhasil disimpan',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.$modal.hide('my-modal')
                            this.getData(0)
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });
                }
            },
            // Edit data periode
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/bidang/edit?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.id_user = response.data.data[0].id_user;
                        this.form.bidang = response.data.data[0].bidang;
                        this.form.singkatan = response.data.data[0].singkatan;
                        this.form.kabid_nama = response.data.data[0].kabid_nama;
                        this.form.kabid_nip = response.data.data[0].kabid_nip;
                        this.form.kabid_gol = response.data.data[0].kabid_gol;
                        this.form.nomor_surat = response.data.data[0].nomor_surat;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data periode
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/bidang/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            this.$swal(
                                'Deleted!',
                                response.data.message,
                                'success'
                            )
                            this.getData(0);
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>